<template>
    <div class="dot">
        <div class="header">
            <i @click="go('/dotList')" class="el-icon-arrow-left"></i>
            <span>智能点数</span>
            <span>离线</span>
        </div>
        <div>
            <div class="pig-box">
                <!-- <i class="el-icon-delete corner"></i> -->
                <img :src="pig.face" alt="">
                <!-- <div class="info">
                    <div>
                        <span class="id">{{ pig.id }}</span>
                    </div>
                    <div class="time">{{ formatDate(new Date(),'yyyy-MM-dd hh:mm:ss') }}</div>
                </div> -->
            </div>

            <el-card style="margin:10px;text-align: left;padding: 0;">
                <van-cell-group style="padding: 10px;">
                    <div data-v-28a5ce7f="" class="van-cell">
                        <div data-v-28a5ce7f="" class="van-cell__title">
                            <span class="big-title">李叔</span>
                            <!-- <div data-v-28a5ce7f="" class="van-cell__label">描述信息</div> -->
                        </div>
                        <div data-v-28a5ce7f="" class="van-cell__value">
                            <div data-v-28a5ce7f="">已采集</div>
                        </div>
                    </div>
                    <div data-v-28a5ce7f="" class="van-cell">
                        <div data-v-28a5ce7f="" class="van-cell__title">
                            <span data-v-28a5ce7f="">农户信息</span>
                            <!-- <div data-v-28a5ce7f="" class="van-cell__label">描述信息</div> -->
                        </div>
                        <div data-v-28a5ce7f="" class="van-cell__value">
                            <div data-v-28a5ce7f="">李叔</div>
                            <div data-v-28a5ce7f="">450********1629</div>
                        </div>
                    </div>
                    <van-cell title="投保数量" value="5头" />
                    <van-cell title="投保险种" value="生猪保险+期货" />
                    <!-- <van-cell title="验标方式" value="智能点数" /> -->
                </van-cell-group>
                <!-- <div class="btn-box">
                    <div>
                        <i class="fa fa-plane"></i>
                        离线采集
                    </div>
                    <div @click="go('/scanFace2')">
                        <i class="fa fa-camera"></i>
                        在线采集
                    </div>
                </div> -->
            </el-card>
        </div>
    </div>
</template>
<script>
import { formatDate } from '@/utils/utils'
export default{
    data() {
        return {
            value1:'',
            pig:{
                face:require('@/assets/img/组 5.png'),
                id:'13581248',
                date:'2024-03-08 14:35'
            },
        }
    },
    methods: {
        formatDate(date,format){
            return formatDate(date,format)
        }
    },
}
</script>
<style lang="stylus" scoped>
.dpt{
    background-color: #EEEEEC;
}

.header{
    background-color: #04C694;
    height: 2.5rem;
    line-height: 2.5rem;
    color: #fff;
    display flex;
    justify-content space-between;
    align-items center
    box-sizing border-box;
    padding 0 20px;
    i{
        font-size 26px;
        font-weight 600
    }
}

.big-title{
    font-size: 18px;
    border-left: 3px solid #04c694;
    padding-left: 10px;
}

.btn-box{
    display: flex;
    position: relative;
    div{
        color: #fff;
        width: 50%;
        text-align: center;
        padding: 20px;
        cursor: pointer;
        font-size: 20px;
    }
    div:nth-child(1){
        background-color: #F89052;
    }
    div:nth-child(2){
        background-color: #009D4F;
    }
    
}

.pig-box{
    margin 10px 0;
    position relative;
    background-color #fff;
    padding-bottom 10px;
    img{
        width 100%;
    }

    .info{
        text-align left ;
        padding 0 10px; 
        .id{
            float right;
            color #029A64;
        }
        .time{
            font-size 14px;
            margin-top 5px;
        }
    }
}


</style>
<style>
.dot .el-card__body, .dot .el-main{
    padding: 0;
}
</style>